import React from 'react';
import { Link } from "react-router-dom";

import './style.scss';

import logo from "../../assets/svg/logo.svg";
import cognitive from "../../assets/svg/partners/cognitive.svg";
import redCross from "../../assets/svg/partners/redCross.svg";
import mashaFund from "../../assets/svg/partners/mashaFund.svg";
//import rozirvykolo from "../../assets/svg/partners/rozirvykolo.svg";
//import unfpa from "../../assets/svg/partners/unfpa.svg";

const Footer = () => {
    return (
        <footer className = ' footer'>
            <div className = 'footer__wrap m-width'>
                <div className = 'footer__first' >
                    {/*                    <div className = 'footer__logos'>
                        <Link className = 'footer__main-logo' to = '/'>
                            <img alt = 'logo' className = 'footer__main-logo__img' src = { logo } />
                        </Link>
                    </div>*/}
                    <div className = 'footer__partners'>
                        <Link className = 'footer__logo footer__logo-main' to = '/'>
                            <img alt = 'logo' className = 'footer__main-logo__img' src = { logo } />
                        </Link>
                        <a className = 'footer__logo footer__logo-cognitive' href = 'https://www.cognitive.com.ua' target = { '_blank' }>
                            <img alt = 'cognitive' className = 'footer__partner-logo__img' src = { cognitive } />
                        </a>
                        <a className = 'footer__logo footer__logo-redC' href = 'https://redcross.org.ua/' target = { '_blank' }>
                            <img alt = 'Червоний Хрест' className = 'footer__partner-logo__img' src = { redCross } />
                        </a>
                        <span className = 'footer__logo footer__logo-masha'>
                            <img alt = 'Фонд Маша' className = 'footer__partner-logo__img' src = { mashaFund } />
                        </span>

                        {/*                        <a className = 'footer__logo footer__logo-UNFPA' href = 'https://ukraine.unfpa.org/uk' target = { '_blank' }>
                            <img alt = 'UNFPA' className = 'footer__partner-logo__img' src = { unfpa } />
                        </a>

                        <a className = 'footer__logo footer__logo-kolo' href = 'https://rozirvykolo.org' target = { '_blank' }>
                            <img alt = 'Розірви коло' className = 'footer__partner-logo__img' src = { rozirvykolo } />
                        </a>*/}
                    </div>
                </div>
                <div className = 'footer__second'>
                    <ul className = 'contacts__list'>

                        {/*                        <li className = 'contacts__item contacts__item-phone'>
                            <div>
                                <i className = 'icon icon-phone contacts__icon' />
                            </div>
                            <div>
                                <div className = 'contacts_label'>
                                    <span className = 'contacts__title'>Телефон:</span>
                                </div>
                                <a className = 'contacts_link contacts_phone' href = 'tel:+380683087474'>
                                    <span>+38 (068) 308 74 74</span>
                                </a>
                            </div>
                        </li>*/}

                        <li className = 'contacts__item contacts__item-address'>
                            <div>
                                <i className = 'icon icon-marker contacts__icon' />
                            </div>
                            <div>
                                <div className = 'contacts_label'>
                                    <span className = 'contacts__title'>Адреса:</span>
                                </div>
                                <a
                                    className = ' contacts_link contacts_map'
                                    href = 'https://goo.gl/maps/rKkGDYsTcHGLVL9P6'
                                    rel = 'noopener noreferrer' target = '_blank'>
                                    <span>Інститут когнітивного. Моделювання (ІК.М) 04080, м.Київ, вул.Кирилівська, 82</span>
                                </a>
                            </div>
                        </li>
                        <li className = 'contacts__item contacts__item-email'>
                            <div>
                                <i className = 'icon icon-mail contacts__icon' />
                            </div>
                            <div>
                                <div className = 'contacts_label'>
                                    <span className = 'contacts__title'>Email:</span>
                                </div>
                                <a
                                    className = ' contacts_link contacts_email'
                                    href = 'mailto:admin@itseems.com.ua'>
                                    <span>admin@itseems.com.ua</span>

                                </a>
                            </div>
                        </li>
                        <li className = 'contacts__item contacts__item-privacy'>
                            <Link className = 'footer__link' to = '/privacy-policy'>
                                Політика конфіденційності
                            </Link>
                            <Link className = 'footer__link' to = '/use-of-site'>
                                Умови користування сайтом
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
