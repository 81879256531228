import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from "history";
import Header from './layout/Header';
import Content from './layout/Content';
import Footer from './layout/Footer';
import Cookie from './layout/Cookie';
import ScrollToTop from './components/ScrollToTop';
import { HelmetProvider } from 'react-helmet-async';
import { HelmetMetaData } from './components/HelmetMetaData';

const customHistory = createBrowserHistory();

const App = () => {
    return (
        <HelmetProvider>
            <Router history = { customHistory }>

                <ScrollToTop />
                <Cookie />
                <Header />
                <Content />
                <Footer />
                <HelmetMetaData />

            </Router>
        </HelmetProvider>
    );
};

export default App;
