import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop (forced) {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    if(forced){
        window.scrollTo(0, 0);
    }

    return null;
}
