import React from "react";
import { Helmet } from 'react-helmet-async';
import { useLocation } from "react-router-dom";
import { URL } from '../utils/constants';

export const HelmetMetaData = (props) => {
    const location = useLocation();

    const currentUrl = `${URL}${location.pathname}`;

    const title = props.title !== undefined ? props.title : "Мені здається. Анонімний онлайн-щоденник - itseems.com.ua";

    const description = props.description !== undefined ? props.description : "Постраждалі від насильства мають вільно говорити про проблему, з якою стикнулися, і знати, що вони не є самотніми";

    const type = props.site !== undefined ? props.site : "website";

    let image;

    switch (props.image) {
        case 'fb':
            image=`${URL}/fav/fb.png`;
            break;
        case 'inst':
            image=`${URL}/fav/inst.png`;
            break;
        case 'tg':
            image=`${URL}/fav/tg.png`;
            break;
        case 'tw':
            image=`${URL}/fav/tw.png`;
            break;
        default:
            image=`${URL}/fav/logo512.png`;
    }

    //const hashtag = props.hashtag !== undefined ? props.hashtag : "#camperstribe";
    //const quote = props.quote !== undefined ? props.quote : "";

    return (
        <Helmet>
            {/*other*/}
            {/*            <meta charset = 'utf-8' />
            <meta content = 'IE=edge' httpEquiv = 'X-UA-Compatible' />
            <meta content = 'website' property = 'type' />
            <meta content = { currentUrl } property = 'url' />
            <meta content = 'width=device-width, initial-scale=1, shrink-to-fit=no' name = 'viewport' />
            <meta content = '#ffffff' name = 'msapplication-TileColor' />
            <meta content = '/ms-icon-144x144.png' name = 'msapplication-TileImage' />
            <meta content = '#ffffff' name = 'theme-color' />
            <meta content = '' name = '_token' />
            <meta content = 'noodp' name = 'robots' />*/}
            {/*title info*/}
            <title>{title}</title>
            <meta content = { title } property = 'title' />
            <meta content = { description } name = 'description' />
            <meta content = { image } property = 'image' />
            <meta content = { type } property = 'type' />
            {/*Graph*/}
            <meta content = 'uk_UA' property = 'og:locale' />
            <meta content = { type } property = 'og:type' />
            <meta content = { title } property = 'og:title' />
            <meta content = { image } property = 'og:image' />
            <meta content = { currentUrl } property = 'og:url' />
            <meta content = 'Itseems' property = 'og:site_name' />
            <meta content = { description } property = 'og:description' />

            {/*<meta content = { hashtag } property = 'og:hashtag' />*/}
        </Helmet>
    );
};
