import React from 'react';
import './style.scss';
import { Link, useLocation, NavLink } from "react-router-dom";

import Btn from "../../components/Btn";
import logo from "../../assets/svg/logo-head.svg";

const Header = () => {

    const location = useLocation();

    const isActive = (hash, find) => {
        return hash === find;
    };

    return (
        <header
            className = { ` header ` }>
            <div
                className = { `header__container m-width` }>

                <Link className = 'main-logo' to = '/'>
                    <img alt = 'logo' className = 'main-logo__img' src = { logo } />
                </Link>
                <div className = 'header_nav'>
                    <div className = 'navigation-wrap'>
                        <nav className = 'navigation'>
                            <ul className = 'navigation__ul'>
                                <li className = 'navigation__item'>
                                    <NavLink
                                        activeClassName = { "" }
                                        className = { `navigation__link ${isActive(location.hash, '#specialists') ? "navigation__link--active" : ""}` }
                                        to = '/your-help'>
                                        <i className = 'icon icon-hands navigation__icon' />
                                        <span className = 'navigation__title'>Твоя допомога</span>
                                    </NavLink>
                                </li>
                                <li className = 'navigation__item'>
                                    <NavLink
                                        activeClassName = { "" }
                                        className = { `navigation__link ${isActive(location.hash, '#specialists') ? "navigation__link--active" : ""}` }
                                        to = '/anonymity'>
                                        <i className = 'icon icon-lock navigation__icon' />
                                        <span className = 'navigation__title'>Про анонімність</span>
                                    </NavLink>
                                </li>
                                <li className = 'navigation__item navigation__story'>
                                    <Btn
                                        verySmall
                                        theme = 'main'
                                        title = 'Написати історію'
                                        url = '/add-history'
                                    />
                                </li>
                            </ul>
                        </nav>
                        <div className = 'navigation__btn'>
                            <Btn
                                nofollow
                                theme = 'second'
                                title = 'написаты историю'
                                url = '/add-history'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
