import { lazy } from 'react';

const routes = [
    {
        path:       ['/'],
        exact:      true,
        components: lazy(
            () => import('../pages/Home')
        ),

        /*
                components: lazy(
            () => {
                return new Promise( resolve => {
                    setTimeout( () => resolve( import("../pages/Home") ), 100300 );
                } );
            }
        )
        */
    },
    {
        path:       '/about',
        components: lazy(
            () => import('../pages/About')
        ),
    },
    {
        path:       '/privacy-policy',
        components: lazy(
            () => import('../pages/PrivacyPolicy')
        ),
    },
    {
        path:       '/use-of-site',
        components: lazy(
            () => import('../pages/UseOfSite')
        ),
    },
    {
        path:       '/your-help',
        components: lazy(
            () => import('../pages/YourHelp')
        ),
    },
    {
        path:       '/add-history',
        components: lazy(
            () => import('../pages/AddHistory')
        ),
    },
    {
        path:       '/anonymity',
        components: lazy(
            () => import('../pages/Anonymity')
        ),
    },
    {
        path:       '/how-write-history',
        components: lazy(
            () => import('../pages/HowWriteHistory')
        ),
    },
    {
        path:       '/history/:id/',
        components: lazy(
            () => import('../pages/HistoryDetail')
        ),
    }
];

export default routes;
