import React, { useState } from 'react';
import './style.scss';
import { NavLink } from 'react-router-dom';

const Cookie = () => {

    const [agreeCookie, setAgreeCookie] = useState(Boolean(window.localStorage.agreeCookie));

    const agree = () => {
        window.localStorage.setItem("agreeCookie", "true");
        setAgreeCookie(true);
    };

    const onClose = () => {
        setAgreeCookie(true);
    };

    if (agreeCookie) {
        return null;
    }

    return (
        <div className = 'cookie'>
            <div className = 'cookie__container'>
                <div className = 'cookie__text-wrap'>
                    <span className = 'cookie__text'>Цей веб-сайт використовує файли cookie для підвищення зручності роботи з веб-сайтом.</span>
                </div>
                <div className = 'cookie__btns'>
                    <NavLink
                        className = 'cookie__link-wrap' to = '/privacy-policy'>
                        <span className = 'cookie__link-text'>Детальніше</span>
                    </NavLink>
                    <button className = { 'cookie__btn' } onClick = { agree }>
                        Приймаю
                    </button>
                    <button className = { 'cookie__close' } onClick = { onClose } />
                </div>
            </div>
        </div>
    );
};

export default Cookie;
