import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import './style.scss';

const Btn = ({
    url,
    theme,
    title,
    onPress = () => {
    },
    full,
    nofollow = false,
    type = 'button',
    small,
    verySmall,
    iconName,
}) => {

    const renderBtn = () => {
        const _rel = nofollow ? 'nofollow' : "";

        if (url) {
            if (url.indexOf("http") !== -1) {
                return (
                    <a
                        className = { `btn btn-${theme} 
                        ${theme==='bots'?`btn-${theme}-${title}`:''}
                        ${full ? "btn-full" : ""}
                        ${small ? "btn-small" : ""}
                                        ${verySmall ? "btn-very-small" : ""}
                        
                        ` }
                        href = { url } rel = { _rel }
                        target = '_blank'
                        rel = 'noreferrer'
                        onClick = { onPress }>
                        <span className = {theme==='bots'? 'btn_text-bots':'btn_text'}>
                            {title}
                        </span>
                        {
                            iconName &&
                            <i className = { `icon icon-${iconName}` } />
                        }
                    </a>
                );
            }

            return (
                <Link
                    className = { `btn btn-${theme}
                     ${full ? "btn-full" : ""}
                     ${small ? "btn-small" : ""}
                                     ${verySmall ? "btn-very-small" : ""}
                     ` }
                    to = { url } onClick = { onPress }>
                    <span className = 'btn_text'>
                        {title}
                    </span>
                    {
                        iconName &&
                        <i className = { `icon icon-${iconName}` } />
                    }
                </Link>
            );

        }

        return (
            <button
                className = { `btn btn-${theme} 
                ${full ? "btn-full" : ""}
                ${small ? "btn-small" : ""}
                ${verySmall ? "btn-very-small" : ""}
                ` }
                type = { type }
                onClick = { onPress }>
                <span className = 'btn_text'>
                    {title}
                </span>
                {
                    iconName &&
                    <i className = { `icon icon-${iconName}` } />
                }
            </button>
        );
    };

    return (<>
        {renderBtn()}
    </>
    );
};

Btn.propTypes = {
    url:       PropTypes.string,
    theme:     PropTypes.string.isRequired,
    title:     PropTypes.string.isRequired,
    onPress:   PropTypes.func,
    width:     PropTypes.string,
    nofollow:  PropTypes.bool,
    type:      PropTypes.string,
    small:     PropTypes.bool,
    verySmall: PropTypes.bool,
    iconName:  PropTypes.string,
};

export default Btn;
